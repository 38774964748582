import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Reactmarkdown from "react-markdown"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import HeaderBuero from "../../components/header_buero"
import Employees from "../../components/thumbs-mitarbeiter"

const MitarbeiterPage = ({ data }) => {
  const headerImg = data.allStrapiMitarbeiter.edges[0].node.picture.localFile.childImageSharp.fluid;
  const headerText = data.allStrapiMitarbeiter.edges[0].node.content

  return (
    <Layout>
      <SEO title="Mitarbeiter" />
      <HeaderBuero />
      <Img fluid={ headerImg } className="heroimage"/>
      <Reactmarkdown>{ headerText }</Reactmarkdown>
      <Employees />
    </Layout>
  )
}

export default MitarbeiterPage

export const query = graphql`
  query QueryMitarbeiter {
    allStrapiMitarbeiter {
      edges {
        node {
          content
          picture {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
